.fixSlide {
  height: 1.493333rem;
}

.fixSlideBox {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  position: fixed;
  bottom: -1px;
  left: 50%;
  right: 0;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 11;
}

:global(.hairlines) .fixSlideBox {
  bottom: -0.5px;
}

.fixSlideBox img {
  width: 100%;
  height: 100%;
}

.fixSlideBox .fixSlideImg {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.fixSlideBox {
  background: #fff;
}

.qr {
  display: block;
  width: 100%;
  height: 1.493333rem;
}

.qr:hover {
  text-decoration: none;
}