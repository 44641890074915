.nav {
  background: #fff;
  padding: 0 0.08rem;
  position: relative;
}

.navbox {
  overflow: hidden;
}

.item {
  width: 14.28%;
  font-size: 0.4rem;
  line-height: 0.986667rem;
  float: left;
  text-align: center;
}

.link {
  color: #1a1a1a !important;
  position: relative;
  white-space: nowrap;
}

.more {
  width: 14.28%;
  height: 0.986667rem;
  float: left;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  background: url(./more.png) no-repeat center center/0.293333rem 0.186667rem;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.turn {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.redHot {
  width: 0.08rem;
  height: 0.08rem;
  position: absolute;
  background: #f54343;
  top: -0.026667rem;
  border-radius: 50%;
  right: -0.08rem;
}