.bookrack {
  position: fixed;
  right: 0.4rem;
  bottom: 2.533333rem;
  z-index: 99999;
}

.bookrackImg {
  width: 1.306667rem;
  height: 1.306667rem;
}