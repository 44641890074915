.stickBorder {
  position: relative;
}

.stickBorder::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0.053333rem;
  background: #fff;
  border-bottom: 1px solid #eee;
  z-index: 1;
  margin: 0 0.4rem;
}

:global(.hairlines) .stickBorder::after {
  border-bottom: 0.5px solid #eee;
}