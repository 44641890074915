.header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0.186667rem 0;
  background: #fff;
  border-bottom: 1px solid #f3f3f3;
}

:global(.hairlines) .header {
  border-bottom: 0.5px solid #f3f3f3;
}

.logo {
  display: block;
  width: 2.506667rem;
  height: 0.48rem;
  background: url(./logo.png) no-repeat 0 0/2.506667rem 0.48rem;
  margin: 0.16rem 0.32rem 0 0.346667rem;
}

.searchBox {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 0.8rem;
  margin-right: 0.133333rem;
  position: relative;
}

.searchVal {
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 0.8rem;
  /* line-height: 60px; */
  font-size: 0.346667rem;
  color: #8e8e8e;
  -webkit-appearance: none;
  outline: none;
  background: #f5f5f5;
  border-radius: 0.4rem;
  display: block;
  border: none;
  padding: 0 1.2rem 0 0.24rem;
}

.button {
  font-size: 0.346667rem;
  height: 0.853333rem;
  line-height: 0.8rem;
  color: #8e8e8e;
  width: 1.066667rem;
  position: absolute;
  top: 0;
  right: 0.213333rem;
  text-align: right;
}

.button::before {
  content: '';
  width: 1px;
  height: 0.32rem;
  background: #8e8e8e;
  position: absolute;
  left: 0.133333rem;
  top: 0.24rem;
}

:global(.hairlines) .button::before {
  width: 0.5px;
}

.weather {
  width: 1.653333rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.weatherPic {
  width: 0.64rem;
  height: 0.64rem;
  margin-top: 0.08rem;
  display: block;
}

.weatherInfo {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow: hidden;
}

.temperature {
  font-size: 0.32rem;
  line-height: 0.48rem;
  color: #5c5c5c;
  position: absolute;
  top: 0.053333rem;
  left: 0;
  white-space: nowrap;
}

.city {
  position: absolute;
  top: 0.453333rem;
  left: 0;
  font-size: 0.266667rem;
  width: 0.96rem;
  overflow: hidden;
  line-height: 0.32rem;
  color: #5c5c5c;
  white-space: nowrap;
}

.redHeader {
  position: relative;
  background: #cf1b24;
}

.redHeaderImg {
  width: 100%;
  vertical-align: middle;
}

.redHeader .weather {
  position: absolute;
  right: 0;
  top: 0.813333rem;
}

.redHeader .temperature {
  color: #eabe89;
}

.redHeader .city {
  color: #eabe89;
}

.header .winterOlympicsCopyright {
  width: 2.573333rem;
  margin-right: 0.346667rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.header .winterOlympicsCopyright img {
  width: 2.573333rem;
}

.redHeader .winterOlympicsCopyright {
  width: 2.573333rem;
  margin-right: 0.346667rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  right: 0.346667rem;
  top: 0.666667rem;
}

.redHeader .winterOlympicsCopyright img {
  width: 2.573333rem;
}