.content_list {
  position: absolute;
  top: 2rem;
  left: 0;
  z-index: -1;
}

.content_list_item {
  margin-bottom: 0.666667rem;
}

.chip_item {
  width: 3.2rem;
  height: 0.533333rem;
}

:global body {
  background: #f9f9f9;
  width: 100%;
}